import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "../components/slider";

export default function Aktualnosci({ data }) {
  const posts = data.blog.edges.map(({ node }) => node)

  posts.sort(function(a, b) {
    const dateA = a.frontmatter.date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2.$1.$3").split('.')
    const dateB = b.frontmatter.date.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2.$1.$3").split('.')
    return new Date(dateB[2], dateB[0], dateB[1]) - new Date(dateA[2], dateA[0], dateA[1])
  })

  const seo = {
    title: "Aktualności: Poradnia \"Medicus-Dukla\" & Poradnia Medycyny Rodzinnej",
    description: "Aktualności: Nowy aparat do USG... Zmiany w konfiguracji budynku... Drugie stanowisko w gabinecie stomatologicznym...Bądź na bieżąco z \"Medicus-Dukla\"",
    url: "https://medicusdukla.pl/aktualnosci"
  }

  return (
    <Layout pageClass={null} bg={null}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section data-g="container" className="o-article-entries">
          <Slider />
        <h1 className="a-heading-medium">Aktualności</h1>
        {posts.map((post, key) => (
          <div className="o-article-entry" data-g="grid">
            <a href={post.frontmatter.slug} className="u-absolute-link"></a>
            <div data-g="4 6@md">
              <GatsbyImage className="a-img" image={getImage(post.fields.background)} alt={post.frontmatter.title} />
            </div>
            <div data-g="4 5@md">
              <span> {post.frontmatter.date}</span>
              <h2>
                {post.frontmatter.title}
              </h2>
              <p>
                {post.frontmatter.excerpt}
              </p>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
    query BlogPosts {
        blog: allMarkdownRemark  {
            edges {
                node {
                    html
                    frontmatter {
                        title
                        date
                        background
                        slug
                        excerpt
                    }
                    fields {
                        background {
                            childImageSharp {
                                gatsbyImageData(width: 1920, quality: 80, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
            }
        }
    }`
